import Cookies from 'js-cookie'

let showAlert
let confirm

let globals = { cookies: {} }
// const getCookie = key => Cookies.get(key);
// const getJSONCookie = key => Cookies.getJSON(key);
const setCookie = (key, value) => Cookies.set(key, value, { expires: 365 })
// set expires otherwise its a seesion cookie and will be deleted
// on browser close
const removeCookie = (key) => Cookies.remove(key)

export default {
  setShowAlert: (func) => {
    showAlert = func
  },
  setConfirm: (func) => {
    confirm = func
  },
  getConfirm: () => confirm,
  getShowAlert: () => showAlert,
  set: (data) => {
    if (data) globals = data
    // if (data) is important
    // globals set in multiple places
    // sometimes server side is null
    // sometime client is null
  },
  getConstant: (key) => {
    if (key) return globals[key]
    return globals
  },
  getLoggedUser: () => (globals.cookies ? globals.cookies.loggedUser : null),
  setCookie: (key, val) => {
    setCookie(key, val)
    globals.cookies[key] = val
  },
  getCookie: (key) => (globals.cookies ? globals.cookies[key] : null), // works both client/server
  removeCookie: (key) => {
    removeCookie(key)
    delete globals.cookies[key]
  },
}
