import Router from 'next/router'
import ModifyUrlParam from 'Utils/ModifyUrlParam'

export default function CloseDialog() {
  // remove modal param from url
  // remove redirect url for auth dialogs
  const { query, pathname, asPath } = Router.router
  delete query.modal
  delete query.redirectUrl
  let url = asPath
  url = ModifyUrlParam(url, 'modal', null)
  url = ModifyUrlParam(url, 'redirectUrl', null)
  Router.push({ pathname, query }, url, { shallow: true })
}
