function getMixpanel() {
  if (process.env.DEBUG) return null
  if (typeof window === 'undefined') return null
  if (window && window.mixpanel) return window.mixpanel
  return null
}

export default {
  track: (event, data) => {
    const mixpanel = getMixpanel()
    if (!mixpanel) return
    mixpanel.track(event, data)
  },
  login: (user) => {
    const mixpanel = getMixpanel()
    if (!mixpanel) return
    mixpanel.identify(user.email)
    mixpanel.people.set({
      $email: user.email,
      $name: user.name,
    })
  },
  signup: (user) => {
    const mixpanel = getMixpanel()
    if (!mixpanel) return
    mixpanel.alias(user.email)
  },
  logout: () => {
    const mixpanel = getMixpanel()
    if (!mixpanel) return
    mixpanel.reset()
  },
}
