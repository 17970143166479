// can add, modify, remove based on value. If value == null, will remove
function euc(val) {
  return encodeURIComponent(val)
}

export default function ModifyUrlParam(url, parameter, value) {
  const urlParts = url.split('?')
  const urlBase = urlParts[0] // without parameters
  const paramPrefix = `${euc(parameter)}=`
  let outputUrl = url

  if (urlParts.length >= 2) {
    // has url params
    // remove url base
    urlParts.shift()

    // Join it back up
    const queryString = urlParts.join('?')
    const parts = queryString.split(/[&;]/g)

    let paramExisted = false

    // Reverse iteration as may be destructive
    for (let i = parts.length - 1; i >= 0; i -= 1) {
      if (parts[i].lastIndexOf(paramPrefix, 0) !== -1) {
        // param existed already
        if (value === null) parts.splice(i, 1)
        // remove param
        else parts[i] = `${euc(parameter)}=${euc(value)}`
        paramExisted = true
        break
      }
    }

    if (!paramExisted && value) {
      // if did not exist earlier and needs to be added
      parts.push(`${euc(parameter)}=${euc(value)}`)
    }

    if (parts.length) outputUrl = `${urlBase}?${parts.join('&')}`
    else outputUrl = urlBase
  } else if (value) {
    // no existing url params and wants to add/modify param
    outputUrl = `${urlBase}?${paramPrefix}${euc(value)}`
  }
  return outputUrl
}
