const RandomString = (length = 20, mode) => {
  // mode an, a, n
  let str = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
  if (mode === 'a') {
    str = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'
  } else if (mode === 'n') {
    str = '0123456789'
  }
  let result = ''
  for (let i = length; i > 0; i -= 1)
    result += str[Math.floor(Math.random() * str.length)]
  return result
}

export default RandomString
