import React from 'react'
import App from 'next/app'
import sentryService from 'Services/sentryService'
import Head from 'next/head'
import mixpanelService from 'Services/mixpanelService'
import PageHead from 'Components/PageHead'
import Router, { withRouter } from 'next/router'
import globalsService from 'Services/globalsService'
import CloseDialog from 'Utils/CloseDialog'
import Lazyload from 'Components/Lazyload'
import randomString from 'Utils/RandomString'
import Cookies from 'js-cookie'
import GlobalsContext from 'Contexts/GlobalsContext'
import '../globals.css'

const promiseFinally = require('promise.prototype.finally')

promiseFinally.shim()

const Snackbar = Lazyload(() => import('Components/Snackbar'))
const LoginDialog = Lazyload(() => import('Components/LoginDialog'))
const GoogleAnalytics = Lazyload(() => import('Components/GoogleAnalytics'))
const RequestInstituteDemoDialog = Lazyload(() =>
  import('Components/RequestInstituteDemoDialog')
)

Router.events.on('routeChangeStart', () => {})

Router.events.on('routeChangeComplete', () => {
  const { asPath, pathname, query } = Router.router
  mixpanelService.track(`page:${pathname}`, { asPath, query })
})

Router.events.on('routeChangeError', () => {})

let localGlobals = { cookies: {} }
let globalsPopulated = false

function populateGlobals() {
  if (typeof window === 'undefined' || globalsPopulated) return
  const userJsonString = Cookies.get('loggedUser')
  localGlobals = {
    cookies: {
      loggedUser: userJsonString ? JSON.parse(userJsonString) : null,
      authToken: Cookies.get('authToken'),
    },
  }
  globalsPopulated = true
}

class MyApp extends App {
  constructor() {
    super()
    // set globals for client side
    // set globals first so that its accessible by everything
    populateGlobals()
    this.state = {
      snackbarShow: false,
      snackbarMessage: '',
      snackbarVariant: '',
      uniqueSnackbarId: null,
    }
    this.showAlert = this.showAlert.bind(this)
    // setting showalert and confirm in mount can error when
    // they are triggered immediately on page load
    globalsService.setShowAlert(this.showAlert)
  }

  componentDidMount() {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side')
    if (jssStyles) {
      jssStyles.parentNode.removeChild(jssStyles)
    }
    sentryService.init()
    sentryService.login()
  }

  showAlert(message, variant) {
    this.setState({
      uniqueSnackbarId: randomString(20),
      snackbarMessage: message,
      snackbarVariant: variant,
      snackbarShow: true,
    })
  }

  render() {
    const { Component, pageProps, router } = this.props
    const {
      snackbarMessage,
      snackbarShow,
      snackbarVariant,
      uniqueSnackbarId,
    } = this.state
    const { query } = router
    const {
      cookies: { loggedUser },
    } = localGlobals
    return (
      <div>
        <Head>
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0,maximum-scale=1.0,minimum-scale=1.0, user-scalable=no"
          />
        </Head>
        <PageHead />
        <GoogleAnalytics />
        <GlobalsContext.Provider value={localGlobals}>
          <Component {...pageProps} />
          {query && query.modal && (
            <div>
              {(query.modal === 'requestDemo' ||
                query.modal === 'contactUs') && (
                <RequestInstituteDemoDialog onClose={CloseDialog} />
              )}
            </div>
          )}
          {query && query.modal && (
            <div>
              {query.modal === 'login' && !loggedUser && (
                <LoginDialog
                  redirectUrl={query.redirectUrl}
                  onClose={CloseDialog}
                />
              )}
            </div>
          )}
          {snackbarShow && (
            <Snackbar
              key={uniqueSnackbarId}
              messageId={uniqueSnackbarId}
              message={snackbarMessage}
              open={snackbarShow}
              onClose={() => this.setState({ snackbarShow: false })}
              variant={snackbarVariant}
            />
          )}
        </GlobalsContext.Provider>
      </div>
    )
  }
}

export default withRouter(MyApp)
