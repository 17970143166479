import React from 'react'
import Head from 'next/head'
import PropTypes from 'prop-types'

// has dynamic/variable part of the head

const PageHead = ({ title, description, card }) => {
  let metaTitle = title
  let metaDescription = description
  if (!metaTitle) {
    metaTitle = 'Ylurn'
  }
  if (!metaDescription) {
    metaDescription = ''
  }
  return (
    <Head>
      <title>{metaTitle}</title>
      {description && <meta name="description" content={metaDescription} />}
      {
        // add open graph meta tags.
        // see https://ogp.me/#types for types of og.
        card && (
          <>
            <meta property="og:title" content={card.title} />
            <meta property="og:type" content={card.type} />
            <meta property="og:url" content={card.url} />
            <meta property="og:image" content={card.image} />
            {card.description && (
              <meta property="og:description" content={card.description} />
            )}
          </>
        )
      }
    </Head>
  )
}

PageHead.defaultProps = {
  title: '',
  description: '',
  card: null,
}

PageHead.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  card: PropTypes.object,
}

export default PageHead
